import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "cart"
};
const _hoisted_2 = {
  class: "content"
};
import Empty from '../../components/Empty.vue';
import StoreHeader from '../../components/storeHeader.vue';
import cartDetail from './compoents/cartDetail.vue';
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
export default {
  __name: 'cart',
  setup(__props) {
    const isShow = ref(true);
    const store = useStore();
    const init = () => {
      if (store.state.cartList.length === 0) {
        isShow.value = false;
      }
    };
    const changeShow = () => {
      isShow.value = false;
    };
    onMounted(() => {
      init();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(StoreHeader, {
        title: "购物车",
        edit: isShow.value
      }, null, 8, ["edit"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [isShow.value ? (_openBlock(), _createBlock(cartDetail, {
        key: 0,
        changeShow: changeShow
      })) : (_openBlock(), _createBlock(Empty, {
        key: 1
      }))])])], 64);
    };
  }
};