import "core-js/modules/es.array.push.js";
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-fe4c5acc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "cartDetail"
};
const _hoisted_2 = {
  class: "content"
};
const _hoisted_3 = {
  key: 1,
  class: "buy"
};
const _hoisted_4 = {
  class: "left"
};
import { showToast } from 'vant';
import { computed, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import ListItemVue from '../../../components/ListItem.vue';
export default {
  __name: 'cartDetail',
  props: {
    changeShow: function () {}
  },
  setup(__props) {
    const props = __props;
    const router = useRouter();
    const store = useStore();
    const data = reactive({
      checked: [],
      submitChecked: true
    });
    const handleChange = (value, detail) => {
      store.state.cartList.map(item => {
        if (item.id === detail.name) {
          item.num = value;
        }
      });
    };
    const init = () => {
      data.checked = store.state.cartList.map(item => item.id);
    };
    onMounted(() => {
      // console.log('onMounted')
      init();
    });
    const onSubmit = () => {
      console.log('提交定单');
      if (data.checked.length) {
        store.commit('submitOrder', updateData(true));
        router.push({
          path: '/createorder',
          query: {
            list: data.checked
          }
        });
      } else {
        showToast('请选中结算项');
      }
    };
    const choseAll = () => {
      if (data.submitChecked) {
        if (data.checked.length !== store.state.cartList.length) {
          init();
        }
      } else {
        data.checked = [];
      }
    };
    const groupChange = () => {
      if (data.checked.length !== store.state.cartList.length) {
        data.submitChecked = false;
      } else {
        data.submitChecked = true;
      }
    };
    const total = computed(() => {
      const countlist = updateData(true);
      let sum = 0;
      countlist.forEach(item => {
        sum += item.num * item.price;
      });
      return sum * 100;
    });
    const updateData = type => {
      return store.state.cartList.filter(item => {
        return type ? data.checked.includes(item.id) : !data.checked.includes(item.id);
      });
    };
    const handleDelete = () => {
      console.log('handleDelete');
      // data.checked是否有值
      if (data.checked.length) {
        store.commit('delete', updateData(false));
        if (store.state.cartList.length === 0) {
          store.commit('changeDelete');
          props.changeShow();
        }
      } else {
        showToast('请选中删除项');
      }
    };
    return (_ctx, _cache) => {
      const _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
      const _component_van_checkbox = _resolveComponent("van-checkbox");
      const _component_van_submit_bar = _resolveComponent("van-submit-bar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_van_checkbox_group, {
        modelValue: data.checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => data.checked = $event),
        onChange: groupChange
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).state.cartList, (item, index) => {
          return _openBlock(), _createElementBlock("div", {
            key: index
          }, [_createVNode(ListItemVue, {
            itemContent: item,
            handleChange: handleChange,
            showCheckBox: "true"
          }, null, 8, ["itemContent"])]);
        }), 128))]),
        _: 1
      }, 8, ["modelValue"])]), _unref(store).state.isDelete ? (_openBlock(), _createBlock(_component_van_submit_bar, {
        key: 0,
        price: _unref(total),
        "button-text": "提交订单",
        onSubmit: onSubmit,
        class: "submit-all"
      }, {
        default: _withCtx(() => [_createVNode(_component_van_checkbox, {
          modelValue: data.submitChecked,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => data.submitChecked = $event),
          onClick: choseAll
        }, {
          default: _withCtx(() => [_createTextVNode("全选")]),
          _: 1
        }, 8, ["modelValue"])]),
        _: 1
      }, 8, ["price"])) : (_openBlock(), _createElementBlock("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_van_checkbox, {
        modelValue: data.submitChecked,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => data.submitChecked = $event),
        onClick: choseAll
      }, {
        default: _withCtx(() => [_createTextVNode("全选")]),
        _: 1
      }, 8, ["modelValue"])]), _createElementVNode("div", {
        class: "delete",
        onClick: handleDelete
      }, "删除")]))]);
    };
  }
};